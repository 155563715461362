/* PC版のTOPページの各要素の座標算出を管理 */

/**
 * 目的 : マウスオーバー時のXY座標を算出する
 * 引数1 (pointRatioList) : 画像内における各座標のXY座標を管理する座標の割合
 *                      要素1 (widthMin)  : X座標の最小の割合
 *                      要素2 (widthMax)  : X座標の最大の割合
 *                      要素3 (heightMin) : Y座標の最小の割合
 *                      要素4 (heightMax) : Y座標の最大の割合
 * 引数2 (imgWidth)    : 画像の横幅
 * 引数3 (imgWidthSub): 画像の横幅 (トレミングされる幅)
 * 引数4 (imgHeight)   : 画像の縦幅
 */
export function CalcMonseOverPoint(pointRatioList, imgWidth, imgWidthSub, imgHeight){
    var pointList = {widthMin : 0, widthMax : 0, heightMin : 0, heightMax : 0};

    pointList.widthMin = Math.round(imgWidth * pointRatioList.widthMin - imgWidthSub);
    pointList.widthMax = Math.round(imgWidth * pointRatioList.widthMax - imgWidthSub);
    pointList.heightMin = Math.round(imgHeight * pointRatioList.heightMin);
    pointList.heightMax = Math.round(imgHeight * pointRatioList.heightMax);

    return pointList;
}


/**
 * 目的 : コメントエリアの座標を算出
 * 引数1 (point_comment_ratio) :画面内におけるコメントエリアのXY座標を管理する座標の割合
 *                      要素1 (top)     : Y座標の最小の割合
 *                      要素2 (bottom)  : Y座標の最大の割合
 *                      要素3 (side)    : 切り捨てる横幅の割合
 * 引数2 (imgWidth)                : 画像の横幅
 * 引数3 (imgWidthSub)            : 画像の横幅 (トレミングされる幅)
 * 引数6 (commentAreaWidthMax)   : コメントエリアの最大サイズ (横幅)
 * 引数4 (imgHeight)               : 画像の縦幅
 * 引数5 (imgHeightSub)           : 画像の縦幅 (トリミングされる幅)
 * 戻り値 : コメントエリアの各要素
 *              top     : 画面上部からコメントエリア上部までの距離
 *              bottom  : 画面上部からコメントエリア下部までの距離
 *              side    : 画面幅からコメントエリアを除いた大きさ (半分)
 */
export function CalcCommentAreaPoint(pointRatio, imgWidth, imgWidthSub, commentAreaWidthMax, imgHeight, imgHeightSub){
    var pointList = {top: 0, bottom: 0, side: 0};

    pointList.top = Math.round((imgHeight - imgHeightSub) * pointRatio.top);
    pointList.bottom = Math.round((imgHeight - imgHeightSub) * pointRatio.bottom);
    pointList.side = Math.round((imgWidth - imgWidthSub * 2) * pointRatio.side /2 );

    if ((imgWidth - imgWidthSub * 2) - pointList.side * 2 > commentAreaWidthMax ) {
        // コメントエリアの上限を超える場合 : コメントエリアのサイズを小さくする
        pointList.side = ((imgWidth - imgWidthSub * 2) - commentAreaWidthMax) / 2;
    }
    
    return pointList;

}

/**
 * 目的 : 要素のaタグのスタイルを設定
 * 引数1 (conetntId) : 要素のid
 * 引数2 (contentPointList): 要素の座標
 *          widthMin    : 各リンクのX軸の値(最小)
 *          widthMax    : 各リンクのX軸の値(最大)
 *          heightMin   : 各リンクのY軸の値(最小)
 *          heightMax   : 各リンクのY軸の値(最大)
 * 戻り値 : なし
 */
export function SetStyleContent(contentId, contentPointList){
    var element = document.getElementById(contentId);
    element.style.left = contentPointList.widthMin + 'px';
    element.style.width = contentPointList.widthMax - contentPointList.widthMin + 'px';
    element.style.height = contentPointList.heightMax - contentPointList.heightMin + 'px';
    element.style.top = contentPointList.heightMin + 'px';
}

/**
 * 目的 : 各要素の箇所にマウスオーバーをされたかをチェック
 * 引数1 (contentPointList) : 要素の座標
 *          widthMin    : 各リンクのX軸の値(最小)
 *          widthMax    : 各リンクのX軸の値(最大)
 *          heightMin   : 各リンクのY軸の値(最小)
 *          heightMax   : 各リンクのY軸の値(最大)
 * 引数2 (contentImg)       : 要素の名称
 * 引数3 (nowImg)         : 対象の画像
 * 引数4 (nowPoint)         : 現在のマウスのXY座標
 *          pageX       : 現在のマウスのX座標
 *          pageY       : 現在のマウスのY座標
 * 戻り値 : true マウスオーバー中 false:マウスオーバー中でない
 */
export function CheckMouseOverContent(contentPointList, contentImg, nowImg, nowPoint) {
    
    if ( contentPointList.widthMin <= nowPoint.pageX
        && nowPoint.pageX <= contentPointList.widthMax
        && contentPointList.heightMin <= nowPoint.pageY
        && nowPoint.pageY <= contentPointList.heightMax){
            
            /*
            console.log("nowImage:" + nowImg);
            console.log("contentImag:" + contentImg);
            */
            // マウスオーバー時イベント : デザイン
            if (nowImg !== contentImg){
                return true;
            }
    }

    return false;

}