/* 読込: モジュール */
import React, {useState, useEffect} from 'react'
import Modal from 'react-modal'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

/* 読込: コンポーネント */
/* 読込: CSS */
import '../../css/Zine.css';
import 'swiper/css/navigation';
import 'swiper/css';
/* 読込: その他 */

function ZinePc (props) {
    const dayList = ["日", "月", "火", "水", "木", "金", "土"]; // 曜日確認用
    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(255, 255, 255, 0)',
            zIndex: 100
        },
        content: {
            width: '60%',
            height: '70%',
            top: '15%',
            left: '20%',
            right: 'auto',
            bottom: 'auto',
            background: 'rgba(252, 250, 235, 0.94)',
            border: '0px',
            borderRadius: '20px',
            transition: 'opacity 200ms ease-in-out',
        }
    }; 

    const [zineList, setZineList] = useState([]);                               // 一覧表示するZineのリスト
    const [firstLoadFlg, setFirstLoadFlg] = useState(true);                     // 1回目の読み込みかどうか (true:1回目の読み込み false:2回目以降の読み込み)
    const [modalOpenFlg, setModalOpenFlg] = useState(false);                    // 画面内ウィンドウ(モーダルウィンドウ)を開くかどうか (true:開く false:開かない)
    const [zinePageImgList, setZinePageImgList] = useState([])                  // モーダル用 : 各画像のパス
    const [zineExplanationList, setZineExplanationList] = useState([]);         // 
    const [zineCreatedYmd, setZineCreatedYmd] = useState("");                   // 
    const [zineSlidesPerViewCount, setZineSlidesPerViewCount] = useState(3);    //
    const [targetZineId, setTargetZineId] = useState(0);                        // 選択中のZineのZineId

    var serverConnectFlg = false;   // サーバーとの通信中かどうかを管理 (true:通信中 false:通信中でない)
    var zineKeyList = [];          // 表示している記事のキー配列


    const getList = async() => {
        if (!serverConnectFlg) {
            serverConnectFlg = true;

            const params = { // 渡したいパラメータをJSON形式で書く
            };

            const query_params = new URLSearchParams(params);
            await fetch(process.env.REACT_APP_BACKEND_BASE_URL + 'zine/list?' + query_params)
            .then(resp => resp.json())
            .then(res =>{
                // Expressの処理でエラーがあった場合
                if (Array.isArray(res.errors) && res.errors.length > 0){
                    for (var i = 0; i < res.errors.length; i++){
                        var error = res.errors[i];
                        console.error(error.path + "(val:" + error.value + "):" + error.msg);
                    }
                }else{
                    // データをセット
                    const resList = JSON.parse(res);
                    for (let key in resList) {
                        if (zineKeyList.indexOf(resList[key]['datZine']['zineId']) === -1){
                            // 日付の形式を変更
                            var createDateStr = resList[key]['datZine']['createdAt'].substr(0, 10);
                            createDateStr = createDateStr.replace(/-/g, '/');
                            const createDate = new Date(createDateStr);

                            // 配列にキーがない場合のみ追加
                            var zineInfo= {
                                key: resList[key]['datZine']['zineId'],
                                title: resList[key]['datZine']['zineTitle'],
                                zineFilePath: resList[key]['zineFile'],
                                explanation: resList[key]['datZine']['explanation'],
                                createdYmd: createDateStr + ' (' + dayList[createDate.getDay()] + ')',
                            };
                            
                            zineKeyList.push(resList[key]['datZine']['zineId']);
                            zineList.push(zineInfo);
                        }
                    }
                }

                if (zineList.length > 0){
                    setZineExplanationList(zineList[0]['explanation'].split(/(?<=。)/g));
                    setZineCreatedYmd(zineList[0]['createdYmd']);
                    setTargetZineId(zineList[0]['key']);
                }
                
                serverConnectFlg = false;
            });   
        }
    }

    /**
     * Zineを切り替えたときに実行される処理
     */
    const setActiveSwiper = (swiper) => {
        const activeIndex = swiper.activeIndex;
        const activeZineId = zineList[activeIndex]['key'];
        setTargetZineId(zineList[activeIndex]['key']);

        zineList.forEach(zineInfo => {
            const imgElement = document.getElementById(zineInfo.key);

            if (zineInfo.key === activeZineId){
                imgElement.className = 'zine-swiper-content-img-target';
            }else {
                imgElement.className = '';
            }
        });
        


        setZineExplanationList(zineList[activeIndex]['explanation'].split(/(?<=。)/g));
        setZineCreatedYmd(zineList[activeIndex]['createdYmd']);
    } 

    /* 
     * 画面サイズ変更時の処理
    */
    function setWindowWidthSize() {
        const parent = document.getElementById('zine-content');
            if (parent.clientWidth < 950) {
                setZineSlidesPerViewCount(1);
            } else if (parent.clientWidth < 1500){
                setZineSlidesPerViewCount(2);
            } else {
                setZineSlidesPerViewCount(3);
            }
    }

    /**
     * 画像クリック時の処理
     * 引数 : zineId : モーダルを開くZineのzineId
     */
    async function openModal(zineId) {

        // 選択中のZineの場合のみ実行
        if (zineId == targetZineId){

            const params = { // 渡したいパラメータをJSON形式で書く
                zineId: zineId
            };

            const query_params = new URLSearchParams(params);
            await fetch(process.env.REACT_APP_BACKEND_BASE_URL + 'zine/detail?' + query_params)
            .then(resp => resp.json())
            .then(res =>{
                // Expressの処理でエラーがあった場合
                if (Array.isArray(res.errors) && res.errors.length > 0){
                    for (var i = 0; i < res.errors.length; i++){
                        var error = res.errors[i];
                        console.error(error.path + "(val:" + error.value + "):" + error.msg);
                    }
                }else{
                    // モーダルの情報をセット
                    const resList = res.split(',');
                    var pageList = [];
                    for(var i = 0; i < resList.length; i++){
                        // 不要な文字が付くので削除
                        var zineImgPath = resList[i].replace("[", "").replace("]","").replace(/\"/g, '')
                        if (i === 0 || i === resList.length -1){
                            // 最初と最後のページは1枚
                            pageList.push(zineImgPath);
                            zinePageImgList.push(pageList);

                        }else if (i % 2 === 1){
                            // 奇数ページの場合
                            pageList = [];
                            pageList.push(zineImgPath);
                        }else {
                            // 偶数ページの場合
                            pageList.push(zineImgPath);
                            zinePageImgList.push(pageList);
                            pageList = [];
                        }
                        
                        
                    }
                    console.log(zinePageImgList);
                    setModalOpenFlg(true);
                }
            })
        }
    }

    useEffect(() => {
        

        window.addEventListener("resize", () => {
            setWindowWidthSize();
        }, false);

        setWindowWidthSize();


        if(firstLoadFlg){
            setFirstLoadFlg(false);
            getList();
        }
    });

    return (
        <div id='zine-content' className="zine-content">
            <div className='zine-swiper-parent'>
                <Modal
                    isOpen={modalOpenFlg}
                    onRequestClose={() => {setModalOpenFlg(false); setZinePageImgList([])}}
                    style={customStyles}
                    closeTimeoutMS={300}
                    ariaHideApp={false}
                >
                    <div className="zine-modal-parent">
                        <Swiper
                            className='zine-modal-img-parent'
                            modules={[Navigation]}
                            navigation
                        >
                            {
                            zinePageImgList.map((pageList, index) => {
                                    return  (
                                        <SwiperSlide key={index}>
                                            {
                                                pageList.map((path, index2) => {
                                                    return (
                                                    <img className='zine-modal-img' src={path} alt={index2} key={index2} />
                                                    )
                                                })
                                            }
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    </div>
                </Modal>
                <Swiper
                    navigation
                    slidesPerView={zineSlidesPerViewCount}
                    spaceBetween={30}
                    centeredSlides={true}
                    modules={[Navigation]}
                    updateOnWindowResize={true}
                    onSlideChange={setActiveSwiper}
                >
                    {zineList.map((zineInfo) => (
                    <SwiperSlide key={zineInfo.key}>
                        <a href='#' onClick={() => openModal(zineInfo.key)} className='zine-swiper-link'>
                        <div className='zine-swiper-content'>
                            {targetZineId === zineInfo.key && <img id={zineInfo.key} src={zineInfo.zineFilePath} className='zine-swiper-content-img-target'/>}
                            {targetZineId !== zineInfo.key && <img id={zineInfo.key} src={zineInfo.zineFilePath} />}
                            <div className='zine-swiper-content-title'>
                            {targetZineId === zineInfo.key && <h1>{"「 " + zineInfo.title + " 」"}</h1>}
                            {targetZineId !== zineInfo.key && <h1></h1>}
                            </div>
                        </div>
                        </a>
                    </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <div className='zine-explanation-area'>
                <div className='zine-explanation-area-content'>
                    {zineExplanationList.map((text, index) => {
                        return (
                            <span key={index}>{text}</span>
                        )
                    })}
                </div>
                <div className='zine-explanation-area-datetime'>
                    <hr/>
                    <span>{"作成日 :" + zineCreatedYmd}</span>
                </div>
            </div>
        </div>
    );
}

export default ZinePc;