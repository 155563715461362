/* 読込: モジュール */
import React, {useEffect} from 'react'
import ActionTalk from '../../method/ActionTalk.js';
import {CalcMonseOverPoint ,CalcCommentAreaPoint, SetStyleContent, CheckMouseOverContent} from '../../method/CalculationPoint.js';
/* 読込: コンポーネント */
/* 読込: CSS */
import '../../css/Top.css';
/* 読込: その他 */
import ImgTop from '../../images/top/top_pc.webp';
import ImgTopTalk from '../../images/top/top_pc_talk.webp';
import ImgTopSystem from '../../images/top/selected/selected_system.webp';
import ImgTopDrawing from '../../images/top/selected/selected_drawing.webp';
import ImgTopDesign from '../../images/top/selected/selected_design.webp';
import ImgTopMusic from '../../images/top/selected/selected_music.webp';
import ImgTopZine from '../../images/top/selected/selected_zine.webp';

// リンクの座標(16:9における割合) : PC
const iconRatioSystem = {widthMin:0.73821,
                            widthMax:0.84553,
                            heightMin:0.24746,
                            heightMax: 0.43503};
const iconRatioDrawing = {widthMin:0.66067,
                            widthMax:0.72581,
                            heightMin:0.22373,
                            heightMax: 0.33446};
const iconRatioDesign = {widthMin:0.58745,
                            widthMax:0.65136,
                            heightMin:0.24746,
                            heightMax: 0.39774};
const iconRatioZine = {widthMin:0.63275,
                            widthMax:0.68052,
                            heightMin:0.43390,
                            heightMax: 0.54689};
const iconRatioMusic = {widthMin:0.27916,
                            widthMax:0.36973,
                            heightMin:0.33107,
                            heightMax: 0.54689};

// コメント 幅
const commentWidthMax = 900;

// コメントの文章
// 挨拶
var commentContentAisatu = [  "岩切商店へようこそ！店主の岩切恭平です。",
                                "各ページのリンクは、黄色枠のコンテンツをクリックしてください。",
                                "気に入ってもらえるものがあれば光栄です。"
                                ];
// プログラム
var commentContentSystem = [ "そのパソコンからは、過去に作製したシステムなどを確認できます。",
                                "お仕事のご依頼をご検討いただいている方は、参考にしてください。",
                                "念のため、使用した言語やフレームワークなども掲載しています。"
                                ];

// 絵
var commentContentDrawing = [ "そのイラストにご興味を持っていただけましたか！",
                                "私が過去に描いたイラストをいくつか掲載していますので、",
                                "もしよろしければ見ていってください。"];

// デザイン
var commentContentDesign = [ "そこは過去に作製したデザインを掲載しています。",
                               "ほとんどは、過去に勤めていた企業内でデザインしたものになります。",
                               "独学で作製しているので、見られると少し恥ずかしいんですがね。"];

// 音楽
var commentContentMusic = [ "そのラジカセから流れている音楽にご興味がありますか？",
                              "その曲は、私がやっているバンドで過去に作製したものです。",
                              "もしバンドにご興味があれば、是非見ていってください。"];

// ZINE
var commentContentZine = [ "その冊子は、過去に作製したZineの1つです。",
                             "「Zineって何？」ですか？簡単にいうと、個人で作製する趣味の冊子ですかね。",
                             "少ないですが、サイト内で読めますのでよかったら読んでいってください。"];

// リンクの座標(実際の座標) : PC
var iconPointSystem = {widthMin:0, widthMax:0, heightMin:0, heightMax: 0};
var iconPointDrawing = {widthMin:0, widthMax:0, heightMin:0, heightMax: 0};
var iconPointDesign = {widthMin:0, widthMax:0, heightMin:0, heightMax: 0};
var iconPointZine = {widthMin:0, widthMax:0, heightMin:0, heightMax: 0};
var iconPointMusic = {widthMin:0, widthMax:0, heightMin:0, heightMax: 0};

// コメントの座標(割合) : PC
//var commentRatio = {top: 0.57212, bottom: 0.93032, side:0.15};
var commentRatio = {top: 0.66, bottom: 0.93032, side:0.2};
// コメントの座標(実際の座標) : PC
var commentPoint = {top: 0, bottom: 0, side: 0};


var nowImage = '';
var topStartFlg = true;

function TopPc (props) {

    var topContent = document.getElementById('top-content');
    var parentWidth = topContent.clientWidth;
    var parentHeight = topContent.clientHeight;

    // マウスオーバーの領域を算出 (プログラム)
    var saidaiKoyakusu = 0 ;
    saidaiKoyakusu = parentHeight / 9     // 現在の高さを16:9の9とするために、最小公倍数を算出
    var imgWidth = saidaiKoyakusu * 16;   // 使われている画像のサイズ (横幅)
    var imgHeight = 0;                     // 使われている画像のサイズ (縦幅)
    var imgWidthSub = 0;                  // トリミングされる幅 (width)
    var imgHeightSub = 0;                 // トリミングされる幅 (height)

    // 画像のサイズを算出
    if (imgWidth > parentWidth){
        // 横比率が16未満の場合 (横を左右からトリミング)
        // 横幅 : 算出済み
        // 縦幅 : 画面サイズを使用
        imgHeight = parentHeight;
        imgWidthSub = Math.round((imgWidth - parentWidth) / 2)
    }else if (imgWidth < parentWidth){
        // 横比率が16以上の場合 (縦を下からトリミング)
        // 横幅 : 画面サイズを使用
        // 縦幅 : 算出
        imgWidth = parentWidth;
        imgHeight = imgWidth / 16 * 9
    }else {
        // 16:9の場合
        // 横幅 : 画面サイズを使用
        // 縦幅 : 画面サイズを使用
        imgWidth = parentWidth;
        imgHeight = parentHeight;
    }

    imgHeightSub = Math.round(imgHeight - parentHeight);

    // 図におけるマウスオーバー時の座標を算出 ( プログラム )
    iconPointSystem = CalcMonseOverPoint(iconRatioSystem, imgWidth, imgWidthSub, imgHeight);

    // 図におけるマウスオーバー時の座標を算出 ( 絵 )
    iconPointDrawing = CalcMonseOverPoint(iconRatioDrawing, imgWidth, imgWidthSub, imgHeight);

    // 図におけるマウスオーバー時の座標を算出 ( デザイン )
    iconPointDesign = CalcMonseOverPoint(iconRatioDesign, imgWidth, imgWidthSub, imgHeight);

    // 図におけるマウスオーバー時の座標を算出 ( ZINE )
    iconPointZine = CalcMonseOverPoint(iconRatioZine, imgWidth, imgWidthSub, imgHeight);

    // 図におけるマウスオーバー時の座標を算出 ( 音楽 )
    iconPointMusic = CalcMonseOverPoint(iconRatioMusic, imgWidth, imgWidthSub, imgHeight);

    // テキストエリアの座標を算出
    commentPoint = CalcCommentAreaPoint(commentRatio, imgWidth, imgWidthSub, commentWidthMax, imgHeight, imgHeightSub);

    useEffect(() => {
        /* 初回処理 */
        if (topStartFlg){
            topStartFlg = false;
            
            window.addEventListener("resize", () => {
                // リサイズ処理の完了後に実施
                //リサイズ後の各座標を再計算
                topContent = document.getElementById('top-content');
                parentWidth = topContent.clientWidth;
                parentHeight = topContent.clientHeight;

                // マウスオーバーの領域を算出 (プログラム)
                saidaiKoyakusu = 0 ;
                saidaiKoyakusu = parentHeight / 9     // 現在の高さを16:9の9とするために、最小公倍数を算出
                imgWidth = saidaiKoyakusu * 16;   // 使われている画像のサイズ (横幅)
                imgHeight = 0;                     // 使われている画像のサイズ (縦幅)
                imgWidthSub = 0;                  // トリミングされる幅 (width)
                imgHeightSub = 0;                 // トリミングされる幅 (height)

                // 画像のサイズを算出
                if (imgWidth > parentWidth){
                    // 横比率が16未満の場合 (横を左右からトリミング)
                    // 横幅 : 算出済み
                    // 縦幅 : 画面サイズを使用
                    imgHeight = parentHeight;
                    imgWidthSub = Math.round((imgWidth - parentWidth) / 2)
                }else if (imgWidth < parentWidth){
                    // 横比率が16以上の場合 (縦を下からトリミング)
                    // 横幅 : 画面サイズを使用
                    // 縦幅 : 算出
                    imgWidth = parentWidth;
                    imgHeight = imgWidth / 16 * 9
                }else {
                    // 16:9の場合
                    // 横幅 : 画面サイズを使用
                    // 縦幅 : 画面サイズを使用
                    imgWidth = parentWidth;
                    imgHeight = parentHeight;
                }

                imgHeightSub = Math.round(imgHeight - parentHeight);

                // 図におけるマウスオーバー時の座標を算出 ( プログラム )
                iconPointSystem = CalcMonseOverPoint(iconRatioSystem, imgWidth, imgWidthSub, imgHeight);

                // 図におけるマウスオーバー時の座標を算出 ( 絵 )
                iconPointDrawing = CalcMonseOverPoint(iconRatioDrawing, imgWidth, imgWidthSub, imgHeight);

                // 図におけるマウスオーバー時の座標を算出 ( デザイン )
                iconPointDesign = CalcMonseOverPoint(iconRatioDesign, imgWidth, imgWidthSub, imgHeight);

                // 図におけるマウスオーバー時の座標を算出 ( ZINE )
                iconPointZine = CalcMonseOverPoint(iconRatioZine, imgWidth, imgWidthSub, imgHeight);

                // 図におけるマウスオーバー時の座標を算出 ( 音楽 )
                iconPointMusic = CalcMonseOverPoint(iconRatioMusic, imgWidth, imgWidthSub, imgHeight);

                // テキストエリアの座標を算出
                commentPoint = CalcCommentAreaPoint(commentRatio, imgWidth, imgWidthSub, commentWidthMax, imgHeight, imgHeightSub);
                
                // リンクをセット:プログラム
                SetStyleContent('link-system', iconPointSystem);

                // リンクをセット:絵
                SetStyleContent('link-paint', iconPointDrawing);

                // リンクをセット:デザイン
                SetStyleContent('link-design', iconPointDesign);

                // リンクをセット:音楽
                SetStyleContent('link-music', iconPointMusic);

                // リンクをセット:ZINE
                SetStyleContent('link-zine', iconPointZine);

                var textArea = document.getElementById('text-parent');
                textArea.style.top = commentPoint.top + "px";
                textArea.style.left = commentPoint.side + "px";
                textArea.style.height = commentPoint.bottom - commentPoint.top + "px";
                textArea.style.width = (imgWidth - imgWidthSub * 2) - commentPoint.side * 2 + "px";
            }, false);

            // リンクをセット:プログラム
            SetStyleContent('link-system', iconPointSystem);

            // リンクをセット:絵
            SetStyleContent('link-paint', iconPointDrawing);

            // リンクをセット:デザイン
            SetStyleContent('link-design', iconPointDesign);

            // リンクをセット:音楽
            SetStyleContent('link-music', iconPointMusic);

            // リンクをセット:ZINE
            SetStyleContent('link-zine', iconPointZine);

            const sleep =  msec => new Promise(resolve => setTimeout(resolve, msec));
            (async () => {
                await sleep(3000);

                // 3秒停止後の処理

                // テキストエリアをセット
                var textArea = document.getElementById('text-parent');
                textArea.style.top = commentPoint.top + "px";
                textArea.style.left = commentPoint.side + "px";
                textArea.style.height = commentPoint.bottom - commentPoint.top + "px";
                textArea.style.width = (imgWidth - imgWidthSub * 2) - commentPoint.side * 2 + "px";
                textArea.style.visibility = "visible"; 

                // 挨拶文を朗読
                ActionTalk('text-content', "岩切商店 店長", commentContentAisatu, 'top-image-action', ImgTopTalk);

                document.addEventListener('onmousemove', onmousemove);
                onmousemove = function(e) {
                    
                    // マウスオーバー時のイベントをセット
                    if (CheckMouseOverContent(iconPointSystem, 'system', nowImage, e)){
                        // マウスオーバー時イベント : プログラム
                        nowImage = 'system';
                        ActionTalk('text-content', "岩切商店 店長", commentContentSystem, 'top-image-action', ImgTopTalk, 'top-image-selected', ImgTopSystem)
                    } else if (CheckMouseOverContent(iconPointDrawing, 'drawing', nowImage, e)){
                        // マウスオーバー時イベント : 絵
                        nowImage = 'drawing';
                        ActionTalk('text-content', "岩切商店 店長", commentContentDrawing, 'top-image-action', ImgTopTalk, 'top-image-selected', ImgTopDrawing);
                    } else if (CheckMouseOverContent(iconPointDesign, 'design', nowImage, e)){
                        // マウスオーバー時イベント : デザイン
                        nowImage = 'design';
                        ActionTalk('text-content', "岩切商店 店長", commentContentDesign, 'top-image-action', ImgTopTalk, 'top-image-selected', ImgTopDesign);
                    } else if (CheckMouseOverContent(iconPointZine, 'zine', nowImage, e)){
                        // マウスオーバー時イベント : ZINE
                        nowImage = 'zine';
                        ActionTalk('text-content', "岩切商店 店長", commentContentZine, 'top-image-action', ImgTopTalk, 'top-image-selected', ImgTopZine);
                    } else if (CheckMouseOverContent(iconPointMusic, 'music', nowImage, e)){
                        // マウスオーバー時イベント : 音楽
                        nowImage = 'music';
                        ActionTalk('text-content', "岩切商店 店長", commentContentMusic, 'top-image-action', ImgTopTalk, 'top-image-selected', ImgTopMusic);
                    }
                }
                await sleep(7000);
            })();
        }

         // シミュレーションとして2秒後に読み込み完了を通知
        const timer = setTimeout(() => {
            if (props.onLoadComplete) {
            props.onLoadComplete(); // 親コンポーネントに通知
            }
        }, 2000);
  
        return () => clearTimeout(timer); // クリーンアップ
    }, [props.onLoadComplete]);

    return (
        <div>
            <div id='top-content' className='top-content'>
                <img id='top-image-selected' src={ImgTopSystem} alt='選択中のコンテンツ'></img>
                <img id='top-image-action' src={ImgTopTalk} alt='店長の口の動き'></img>
                <img id='top-image' src={ImgTop} alt='背景画像(岩切商店)'></img>
                {/* 各コンテントのリンク */}
                <a id='link-system' className='content-link' href="/system"> </a>
                <a id='link-paint' className='content-link' href="/paint"> </a>
                <a id='link-design' className='content-link' href="/design"> </a>
                <a id='link-zine' className='content-link' href="/zine"> </a>
                <a id='link-music' className='content-link' href="/music"> </a>
                {/* 会話用 */}
                <div id='text-parent'>
                    {/* 表示するテキスト */}
                    <span id='text-content'>
                    </span>
                </div>
            </div>
        </div>
    );
}

export default TopPc;