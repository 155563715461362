/* 読込: モジュール */
import React, {useState, useEffect} from 'react'
/* 読込: コンポーネント */
/* 読込: CSS */
/* 読込: その他 */

function ZineSp (props) {
    return (
        <div>
            <p>zine</p>
        </div>
    );
}

export default ZineSp;