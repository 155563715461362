/* 読込: モジュール */
import React, {useState, useEffect} from 'react'
/* 読込: コンポーネント */
/* 読込: CSS */
/* 読込: その他 */

/**
 * 残作業
 */
// ・手動スクロール時に画面内で一番上にあるh2タグのINEXをハイライトする

function SystemDetailIndexPc (props) {

    const [markdownContent, setMarkdownContent] = useState("");
    const [autoScrollFlg, setAutoScrollFlg] = useState(false);  // 自動スクロール中かどうかを管理 (True:自動 False:人力)

    let scrollResolveTimeout;                                   // 自動スクロールの時間を管理
    let selectedClassName = 'report-index-selected';              // 目次要素がクリックされた際に適用するスタイルのクラス名
    let selectNotClassName = 'report-index-not-selected';         // 目次要素がクリックされていない際に適用するスタイル
    let indexIdPattern = 'index-';                              // 目次要素のidで使用されている接頭辞
    
    useEffect(() => {
        
        const parent = document.getElementById('report-parent');    // スクロールバーを表示している親要素
        const header = document.getElementById('header-content');   // ヘッダーの要素
        
        /**
         * 初期化処理 　： 追加
         */
        function clickedStyleReset(selectedClassName) {
            const clickedElementList = document.getElementsByClassName(selectedClassName);
            for (var i = 0; i < clickedElementList.length; i++){
                clickedElementList[i].className = selectNotClassName;
            }
        }

        /**
         * スクロール時の切り分け処理 : 追加
         */
        let setScrollPostion = 0;
        parent.onscroll = () => {
            if (autoScrollFlg) {
                // プログラムによるスクロール
            } else {
                // ユーザーによるスクロール
                var classSetFlg = false;    // clickedクラスをセットしたかどうか
                var h1List = document.querySelectorAll('.report-content-composition-area > h1')

                if (setScrollPostion < parent.scrollTop) {
                    // 下スクロールの場合
                    for(var i = h1List.length - 1; i >= 0; i--){
                        const anchorPosition = h1List[i].getBoundingClientRect();
                        if (!classSetFlg){
                            if (0 < anchorPosition.top && anchorPosition.bottom - header.clientHeight < window.innerHeight){
                                // コンテンツのh2タグが表示されている場合
                                // 一つ目の要素の処理
                                const targetIndex = document.getElementById(indexIdPattern + h1List[i].id);
                                // この要素にクリック状態が選択されているかをチェック
                                if (targetIndex.className === selectedClassName){
                                    // チェックされている場合
                                }else{
                                    // チェックされていない場合
                                    // 既存でチェックされているclassを解除
                                    clickedStyleReset(selectedClassName);
                                    // チェック状態のclassを付与
                                    targetIndex.className = selectedClassName;
                                }
                                classSetFlg = true;
                            }
                        }
                    }
                } else {
                    
                    for(var i = h1List.length - 1; i >= 0; i--){
                        const anchorPosition = h1List[i].getBoundingClientRect();
                        if (!classSetFlg){
                            // 上スクロールの場合
                            if (0 > anchorPosition.top && anchorPosition.bottom - header.clientHeight < window.innerHeight){
                                // コンテンツのh2タグが表示されている場合
                                // 一つ目の要素の処理
                                const targetIndex = document.getElementById(indexIdPattern + h1List[i].id);
                                // この要素にクリック状態が選択されているかをチェック
                                if (targetIndex.className === selectedClassName){
                                    // チェックされている場合
                                }else{
                                    // チェックされていない場合
                                    // 既存でチェックされているclassを解除
                                    clickedStyleReset(selectedClassName);
                                    // チェック状態のclassを付与
                                    targetIndex.className = selectedClassName;
                                }
                                classSetFlg = true;
                            }
                        }
                    }
                }

                setScrollPostion = parent.scrollTop;
            }
        }

        /**
         * スクロール処理 : 追加
         */
        const autoScroll = async(targetPosition) => {
            parent.scrollTo({
                top: targetPosition,
                behavior: 'smooth'
            });
            
            return new Promise((resolve) => {
                const scrollTimeout = setTimeout(() => {;
                    setAutoScrollFlg(false);
                    resolve(true);
                }, 100);
                parent.addEventListener('scroll', function fn (e) {
                    clearTimeout(scrollTimeout);
                    clearTimeout(scrollResolveTimeout);
                    scrollResolveTimeout = setTimeout(function () {
                        // スクロール完了後の処理
                        setAutoScrollFlg(false);
                        parent.removeEventListener('scroll', fn);
                        resolve(true);
                    }, 100);
                });
            });
            
        }

        // 記事全体の内容を取得
        if (props.markdownContent.length > 0) {
            setMarkdownContent(props.markdownContent);
        }
        //querySelectorAllメソッドを使用してページ内のhref属性が#で始まるものを選択
        //forEachメソッドを使って、各アンカータグにクリックされた時の処理
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            anchor.onclick = async(e) => {
                if (!autoScrollFlg) {
                    //自動スクロール中
                    setAutoScrollFlg(true);

                    // クリックされたときのデフォルトの挙動を防ぐ
                    e.preventDefault();
                
                    // クリックされたアンカータグのhref属性を取得
                    const href = anchor.getAttribute('href');
                
                    // href属性の#を取り除いた部分と一致するIDを取得
                    const targetIndex = document.getElementById(href.replace('#', ''));
                
                    // クラスの初期化
                    clickedStyleReset(selectedClassName);
                    
                    // 対象のindexのクラスを変更
                    const indexElement = document.getElementById(String(targetIndex.id));
                    const h2Element = document.getElementById(String(targetIndex.id).substring(indexIdPattern.length));
                    indexElement.className = selectedClassName;

                    // h2タグのmarginTopを取得
                    const {marginTop} = window.getComputedStyle(h2Element);

                    // スクロール先の位置を取得
                    const h2PositionTop = h2Element.getBoundingClientRect().top 
                                            + parent.scrollTop
                                            - header.clientHeight
                                            - parseFloat(marginTop);

                    // 自動スクロールとユーザ操作のスクロールを判別し、ユーザ操作の場合は目次のハイライトを解除 (開始)
                    await autoScroll(h2PositionTop);
                }
            }
        });
        
    });

    return (
        <div className='report-index-area'>
            <div className='report-index-area-inner'>
                <h2 id='report-index-title'>INDEX</h2>
                <ul id='report-index-item'>
                    {
                        (markdownContent.split(/\r\n|\n/)).map((markdownRow, index) => (
                            markdownRow.indexOf('#') == 0 &&
                            <li key={String(Number(index + 1))}>
                                <a id={"index-" + String(Number(index + 1))} href={"#index-" + String(Number(index + 1))} className={selectNotClassName}>{markdownRow.replace('# ', '')}</a>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

export default SystemDetailIndexPc;