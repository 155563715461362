/* 読込: モジュール */
import React, {useEffect} from 'react';
/* 読込: コンポーネント */
/* 読込: CSS */
import '../../css/Loading.css';
/* 読込: その他 */
import imgLoading from '../../images/Loading/Loading.webp';


function Loaging (props) {
    useEffect(() => {
    });

    return (
        <div id='loading_content' className='loading-content'>
            <div id='loading_loader' className='loading-loader'>
                <img src={imgLoading} alt='Loading'></img>
            </div>
        </div>
    );
}

export default Loaging;