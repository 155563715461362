/* 読込: モジュール */
import React, { useEffect } from 'react';
/* 読込: コンポーネント */
/* 読込: CSS */
import '../../css/Contact.css';
/* 読込: その他 */
import ImgContact from '../../images/contact/contact.png';

function ContactPc (props) {
    useEffect(() => {
    }, []);

    return (
        <div>
            <div id='contact-content' className='contact-content'>
                <div>
                    <table>
                        <tr>
                            <td>mail:</td>
                            <td>k-iwakiri@iwakirisyoten.com</td>
                        </tr>
                        <tr>
                            <td>instagram:</td>
                            <td>iwakiri_gallery</td>
                        </tr>
                    </table>
                </div>
                <img id='contact-image' src={ImgContact} alt='背景画像(岩切商店)'></img>
            </div>
        </div>
    );
}

export default ContactPc;