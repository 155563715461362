/* 読込: モジュール */
import React, {useState, useEffect} from 'react'
/* 読込: コンポーネント */
/* 読込: CSS */
/* 読込: その他 */

function SystemListPc (props) {

    const [reportId, setReportId] = useState(0);
    const [title, setTitle] = useState("");
    const [thumbnailPath, setThumbnailPath] = useState("");
    const [prologue, setProlouge] = useState("");
    const [updatedYmd, setUpdateYmd] = useState("");
    const [views, setViews] = useState("");

    useEffect(() => {
        setReportId(props.reportId);
        setTitle(props.title);
        setThumbnailPath(props.thumbnailPath);
        setProlouge(props.prologue);
        setUpdateYmd(props.updatedYmd);
        setViews(props.views);
    });


    return (
        <a className='system-report-link' href={"/system/detail/" + String(reportId)}>
        <div>
            {/* 上部 */}
            <div className='system-report-top-common'>
                <span className='system-report-date'>{updatedYmd}</span>
                <span className='system-report-view'>{views} views</span>
            </div>
            <h2 className='system-report-title'>{title}</h2>
            <hr className='system-report-horizon'/>
            {/* 下部 */}
            <div  className='system-report-bottom-common'>
                <img className='system-report-preview' src={thumbnailPath} />
                <div className='system-report-content-preview'>
                    <div className='system-report-tag'>
                        <ul>
                            {
                                props.tagList.map((tag, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                        <li> {tag} </li>
                                        </React.Fragment>
                                    )
                            })}
                        </ul>
                    </div>
                    <div className='system-report-prologue'>
                        <span>{prologue}</span>
                    </div>
                </div>
            </div>
        </div>
    </a>
    );
}

export default SystemListPc;