/* 読込: モジュール */
import React, {useState, useEffect} from 'react'
/* 読込: コンポーネント */
/* 読込: CSS */
/* 読込: その他 */

function SystemSp (props) {
    return (
        <div>
            <p>system</p>
        </div>
    );
}

export default SystemSp;