/* 読込: モジュール */
//import React, {useState, useEffect} from 'react'
/* 読込: コンポーネント */
/* 読込: CSS */
/* 読込: その他 */

function DrawingSp (props) {
    return (
        <div>
            <p>drawing</p>
        </div>
    );
}

export default DrawingSp;