/* 読込: モジュール */
import React, { useEffect } from 'react';
import ActionTalk from '../../method/ActionTalk.js';
import {CalcCommentAreaPoint} from '../../method/CalculationPoint.js';
/* 読込: コンポーネント */
/* 読込: CSS */
import '../../css/NotFound.css';
/* 読込: その他 */
//import ImgNotFound from '../../images/NotFound.png';
import ImgNotFoundTalk from '../../images/NotFound/notFound_pc_manager_talk.webp';
// 画像 背景
import ImgNotFoundBackgroundGround from '../../images/NotFound/notFound_pc_background_ground.webp';
import ImgNotFoundBackgroundSky from '../../images/NotFound/notFound_pc_background_sky.webp';
// 画像 店長
import ImgNotFoundBackgroundManagerSceneOne from '../../images/NotFound/notFound_pc_manager_scene_1.webp';
import ImgNotFoundBackgroundManagerSceneTwo from '../../images/NotFound/notFound_pc_manager_scene_2.webp';

// コメント 幅
const commentWidthMax = 900;

// コメントの文章
// 挨拶
var commentContent = [  "ここにいらっしゃいましたか！探しましたよ～。",
                        "ここは虚無空間、Webサイトでいう404ページです。",
                        "何もないので、上のロゴからトップページへお戻りください。"
                        ];

// コメントの座標(割合) : PC
//var commentRatio = {top: 0.66, bottom: 0.93032, side:0.2};
var commentRatio = {top: 0.5, bottom: 0.75, side:0.2};
// コメントの座標(実際の座標) : PC
var commentPoint = {top: 0, bottom: 0, side: 0};


const sleep = msec => new Promise(resolve => setTimeout(resolve, msec));

function NotFoundPc (props) {

    var errorContent = document.getElementById('error-content');
    var parentWidth = errorContent.clientWidth;
    var parentHeight = errorContent.clientHeight;

    var saidaiKoyakusu = 0 ;
    saidaiKoyakusu = parentHeight / 9     // 現在の高さを16:9の9とするために、最小公倍数を算出
    var imgWidth = saidaiKoyakusu * 16;   // 使われている画像のサイズ (横幅)
    var imgHeight = 0;                     // 使われている画像のサイズ (縦幅)
    var imgWidthSub = 0;                  // トリミングされる幅 (width)
    var imgHeightSub = 0;                 // トリミングされる幅 (height)

    // 画像のサイズを算出
    if (imgWidth > parentWidth){
        // 横比率が16未満の場合 (横を左右からトリミング)
        // 横幅 : 算出済み
        // 縦幅 : 画面サイズを使用
        imgHeight = parentHeight;
        imgWidthSub = Math.round((imgWidth - parentWidth) / 2)
    }else if (imgWidth < parentWidth){
        // 横比率が16以上の場合 (縦を下からトリミング)
        // 横幅 : 画面サイズを使用
        // 縦幅 : 算出
        imgWidth = parentWidth;
        imgHeight = imgWidth / 16 * 9
    }else {
        // 16:9の場合
        // 横幅 : 画面サイズを使用
        // 縦幅 : 画面サイズを使用
        imgWidth = parentWidth;
        imgHeight = parentHeight;
    }

    // テキストエリアの座標を算出
    commentPoint = CalcCommentAreaPoint(commentRatio, imgWidth, imgWidthSub, commentWidthMax, imgHeight, imgHeightSub);

    useEffect(() => {

        window.addEventListener("resize", () => {
            // リサイズ処理の完了後に実施
            var errorContent = document.getElementById('error-content');
            var parentWidth = errorContent.clientWidth;
            var parentHeight = errorContent.clientHeight;
        
            var saidaiKoyakusu = 0 ;
            saidaiKoyakusu = parentHeight / 9     // 現在の高さを16:9の9とするために、最小公倍数を算出
            var imgWidth = saidaiKoyakusu * 16;   // 使われている画像のサイズ (横幅)
            var imgHeight = 0;                     // 使われている画像のサイズ (縦幅)
            var imgWidthSub = 0;                  // トリミングされる幅 (width)
            var imgHeightSub = 0;                 // トリミングされる幅 (height)
        
            // 画像のサイズを算出
            if (imgWidth > parentWidth){
                // 横比率が16未満の場合 (横を左右からトリミング)
                // 横幅 : 算出済み
                // 縦幅 : 画面サイズを使用
                imgHeight = parentHeight;
                imgWidthSub = Math.round((imgWidth - parentWidth) / 2)
            }else if (imgWidth < parentWidth){
                // 横比率が16以上の場合 (縦を下からトリミング)
                // 横幅 : 画面サイズを使用
                // 縦幅 : 算出
                imgWidth = parentWidth;
                imgHeight = imgWidth / 16 * 9
            }else {
                // 16:9の場合
                // 横幅 : 画面サイズを使用
                // 縦幅 : 画面サイズを使用
                imgWidth = parentWidth;
                imgHeight = parentHeight;
            }
        
            // テキストエリアの座標を算出
            commentPoint = CalcCommentAreaPoint(commentRatio, imgWidth, imgWidthSub, commentWidthMax, imgHeight, imgHeightSub);

            var textArea = document.getElementById('text-parent');
                textArea.style.top = commentPoint.top + "px";
                textArea.style.left = commentPoint.side + "px";
                textArea.style.height = commentPoint.bottom - commentPoint.top + "px";
                textArea.style.width = (imgWidth - imgWidthSub * 2) - commentPoint.side * 2 + "px";
        }, false);

        // 店長を非表示
        var sceneOne = document.getElementById('scene_one');
        var sceneTwo = document.getElementById('scene_two');

        sceneOne.style.display = "none";
        sceneTwo.style.display = "none";

        // 瞬間移動の処理
        (async () => {
            // シーン1(瞬間移動)を表示
            await sleep(2000);
            var date = new Date();
            var timestamp = date.getTime();
            sceneOne.src = ImgNotFoundBackgroundManagerSceneOne +"?"+ timestamp;
            sceneOne.style.display = "block";
            // シーン2(棒立ち)を表示
            await sleep(1500);
            sceneTwo.style.display = "block";

            sceneOne.style.display = "none";

            // メッセージを表示
            // テキストエリアをセット
            var textArea = document.getElementById('text-parent');
            
            textArea.style.top = commentPoint.top + "px";
            textArea.style.left = commentPoint.side + "px";
            textArea.style.height = commentPoint.bottom - commentPoint.top + "px";
            textArea.style.width = (imgWidth - imgWidthSub * 2) - commentPoint.side * 2 + "px";
            textArea.style.visibility = "visible"; 

            // 挨拶文を朗読
            ActionTalk('text-content', "岩切商店 店長", commentContent, 'top-image-action', ImgNotFoundTalk);
        })();
    }, []);

    return (
        <div>
            <div id='error-content' className='error-content'>
                
                {/* ドット絵関連 */}
                <img className='error-content-img-pc' src={ImgNotFoundBackgroundSky} alt='NOT FOUND BACKGROUND SKY' />
                <img className='error-content-img-pc' src={ImgNotFoundBackgroundGround} alt='NOT FOUND BACKGROUND GROUND' />
                <img id='scene_one' className='error-content-img-pc' src={ImgNotFoundBackgroundManagerSceneOne} alt='NOT FOUND BACKGROUND GROUND' />
                <img id='scene_two' className='error-content-img-pc' src={ImgNotFoundBackgroundManagerSceneTwo} alt='NOT FOUND BACKGROUND GROUND' />
                <img id='top-image-action' src={ImgNotFoundTalk}></img>
                {/* 会話用 */}
                <div id='text-parent'>
                    {/* 表示するテキスト */}
                    <span id='text-content'>
                    </span>
                </div>
            </div>
        </div>
    );
}

export default NotFoundPc;