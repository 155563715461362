/* 読込: モジュール */
import React, {useState, useEffect} from 'react'
/* 読込: コンポーネント */
/* 読込: CSS */
import '../../css/System.css';
/* 読込: その他 */

function SystemDetailPc (props) {
    return (
        <div className='system-content'>
            <p>test</p>
        </div>
    )
}

export default SystemDetailPc;