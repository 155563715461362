/* 読込: モジュール */
import React, {useState, useEffect} from 'react'
import Modal from 'react-modal'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import { Swiper, SwiperSlide } from 'swiper/react';
import {Navigation} from 'swiper/modules';
/* 読込: コンポーネント */
/* 読込: CSS */
import '../../css/Design.css';
import 'swiper/css/navigation';
import 'swiper/css';

/* 読込: その他 */

function DesignPc (props) {
    const dayList = ["日", "月", "火", "水", "木", "金", "土"]; // 曜日確認用
    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(255, 255, 255, 0)'
        },
        content: {
            width: '60%',
            height: '70%',
            top: '15%',
            left: '20%',
            right: 'auto',
            bottom: 'auto',
            background: 'rgba(252, 250, 235, 0.94)',
            border: '0px',
            borderRadius: '20px',
            transition: 'opacity 200ms ease-in-out',
        }
    };                                                        // モーダルのレイアウト用
    
    const [windowWidthSize, setWindowWidthSize] = useState(0);      // 画面の幅を取得
    const [designList, setDesignList] = useState([]);               // 一覧表示する画像
    const [designIdLast, setDesignIdLast] = useState(9999);         // 一度に一覧表示する画像の最大ID (初期アクセス時は0)
    const [firstLoadFlg, setFirstLoadFlg] = useState(true);         // 1回目の読み込みかどうか (true:1回目の読み込み false:2回目以降の読み込み)
    const [modalOpenFlg, setModalOpenFlg] = useState(false);        // 画面内ウィンドウ(モーダルウィンドウ)を開くかどうか (true:開く false:開かない)
    const [modalTagList, setModalTagList] = useState([]);           // モーダル用 : タグの一覧
    const [modalCreatedYmd, setModalCreatedYmd] = useState('');     // モーダル用 : 作成日付
    const [modalExplanationList, setModalExplanationList] = useState([]);   // モーダル用 : 説明用
    const [designPageImgList, setDesignPageImgList] = useState([])  // モーダル用 : 各画像のパス

    var serverConnectFlg = false;   // サーバーとの通信中かどうかを管理 (true:通信中 false:通信中でない)
    var designKeyList = [];          // 表示している記事のキー配列

    const getList = async() => {
        if (!serverConnectFlg) {
            serverConnectFlg = true;

            const params = { // 渡したいパラメータをJSON形式で書く
                designIdLast: designIdLast,
                designCount: process.env.REACT_APP_PAINT_COUNT,
                sortType: 1,
                sortTypeDateTime: 1
            };

            const query_params = new URLSearchParams(params);
            await fetch(process.env.REACT_APP_BACKEND_BASE_URL + 'design/list?' + query_params)
            .then(resp => resp.json())
            .then(res =>{
                // Expressの処理でエラーがあった場合
                if (Array.isArray(res.errors) && res.errors.length > 0){
                    for (var i = 0; i < res.errors.length; i++){
                        var error = res.errors[i];
                        console.error(error.path + "(val:" + error.value + "):" + error.msg);
                    }
                }else{
                    // データをセット
                    const resList = JSON.parse(res);
                    for (let key in resList) {
                        if (designKeyList.indexOf(resList[key]['datDesign']['designId']) === -1){
                            // 日付の形式を変更
                            var createDateStr = resList[key]['datDesign']['createdAt'].substr(0, 10);
                            createDateStr = createDateStr.replace(/-/g, '/');
                            const createDate = new Date(createDateStr);

                            // 配列にキーがない場合のみ追加
                            var designInfo= {
                                key: resList[key]['datDesign']['designId'],
                                designFilePath: resList[key]['designFile'],
                                tagList: resList[key]['tagList'],
                                explanation: resList[key]['datDesign']['explanation'],
                                createdYmd: createDateStr + ' (' + dayList[createDate.getDay()] + ')',
                            };
                            
                            designKeyList.push(resList[key]['datDesign']['designId']);
                            designList.push(designInfo);
                        }
                    }

                    // ペイントIDの最大値を更新
                    // 表示中の記事のペイントIDリストの最後の値を取得
                    if (designKeyList.length > 0){
                        setDesignIdLast(designKeyList.slice(-1)[0]);
                    }
                }
                serverConnectFlg = false;
            });   
        }
    }


        /**
     * 一番上なら top、一番下なら bottom、その他は scrolling の文字列を返す
     * 
     * @param {UIEvent} event
     * @returns {String} 'top' | 'bottom' | 'scrolling'
     */
    const getScrollVerticalPosition = e => {
        const {
        scrollHeight, scrollTop, clientHeight
        } = e.target;
    
        const isScrollTop = scrollTop === 0;
        // 完全に最下部に行かなくても検知するようにする
        const isScrollBottom = scrollHeight - clientHeight <= parseInt(scrollTop) + parseInt(scrollTop / 10);
        
        if (isScrollTop) {
        return 'top';
        } else if (isScrollBottom) {
        return 'bottom';
        } else {
        return 'scrolling';
        }
    }

    /**
     * 画像クリック時の処理
     * 引数 : designInfo
     *          - createdYmd     : 作成日付 (例:2024/1/24 (水))
     *          - key           : 識別キー (例:26)
     *          - designFilePath : ファイルのURL (例:http://localhost:3001/static/design/26.jpg)
     *          - taglist       : タグの一覧 (配列)
     */
    async function openModal(designInfo) {
        const params = { // 渡したいパラメータをJSON形式で書く
            designId: designInfo.key
        };

        const query_params = new URLSearchParams(params);
        await fetch(process.env.REACT_APP_BACKEND_BASE_URL + 'design/detail?' + query_params)
        .then(resp => resp.json())
        .then(res =>{
            // Expressの処理でエラーがあった場合
            if (Array.isArray(res.errors) && res.errors.length > 0){
                for (var i = 0; i < res.errors.length; i++){
                    var error = res.errors[i];
                    console.error(error.path + "(val:" + error.value + "):" + error.msg);
                }
            }else{
                // モーダルの情報をセット
                const resList = res.split(',');
                for(var i = 0; i < resList.length; i++){
                    // 不要な文字が付くので削除
                    var designImgPath = resList[i].replace("[", "").replace("]","").replace(/\"/g, '')
                    designPageImgList.push(designImgPath);
                }
                setModalOpenFlg(true);
                setModalTagList(designInfo.tagList);
                setModalCreatedYmd(designInfo.createdYmd);

                setModalExplanationList(designInfo.explanation.split(/(?<=。)/g));
            }
        })
    }

    useEffect(() => {
        setWindowWidthSize(window.innerWidth);
        
        window.addEventListener("resize", () => {
            setWindowWidthSize(window.innerWidth);
        });

        if(firstLoadFlg){
            setFirstLoadFlg(false);
            getList();
        }

        /* 最下部へのスクロール到着を検知 */
        const parent = document.getElementById('design-area-parent');
        parent.onscroll = (element) => {
            // スクロール時の処理
            if (getScrollVerticalPosition(element) === 'bottom'){
                // 最下部での処理
                // サーバーとの通信中でない場合は、各記事の情報を取得
                getList();
            }
        }
    })




    return (
        <div className="design-content">
            <div id="design-area-parent">
                <div>
                    {/* 画像クリック時に詳細用モーダルを表示
                        isOpen : 表示するかどうか
                        onRequestClose : モーダル外部をクリックしたときの処理
                        style: モーダルのスタイル
                    */}
                    <Modal
                        isOpen={modalOpenFlg}
                        onRequestClose={() => {setModalOpenFlg(false); setDesignPageImgList([])}}
                        style={customStyles}
                        closeTimeoutMS={300}
                        ariaHideApp={false}
                        >
                        <div className={windowWidthSize >= 850 ? "design-modal-parent-850-over" : "design-modal-parent-850-under"}>
                            <Swiper
                                className={windowWidthSize >= 850 ? 'design-modal-img-parent-850-over' : 'design-modal-img-parent-850-under'}
                                modules={[Navigation]}
                                navigation
                            >
                                {
                                designPageImgList.map((path, index) => {
                                        return (
                                            <SwiperSlide key={index}>
                                                <img className='design-modal-img' src={path} alt={index} />
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                            <div className={windowWidthSize >= 850 ? 'design-modal-tag-ymd-parent-850-over' : 'design-modal-tag-ymd-parent-850-under'}>
                                <div className='design-mansory-tag'>
                                    <ul>
                                        {
                                        modalTagList.map((tag, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                <li> {tag} </li>
                                                </React.Fragment>
                                            )
                                        })}
                                    </ul>
                                </div>
                                <div className='design-modal-explanation'>
                                    {
                                        modalExplanationList.map((text, index) => {
                                            return (
                                                <span key={index}>{text}</span>
                                            )
                                    })}
                                </div>
                                <hr />
                                <div className={windowWidthSize >= 850 ? 'design-modal-ymd-parent-850-over' : 'design-modal-ymd-parent-850-under'}>
                                    <span>{modalCreatedYmd}</span>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {/* 画面幅に合わせて要素数(横を調整) */}
                    <ResponsiveMasonry
                        columnsCountBreakPoints={{350: 2, 800: 3, 1100: 4, 1300: 5, 1500: 6}}
                    >
                        <Masonry>
                            {designList.map((designInfo) => (
                                <a href='#' onClick={() => openModal(designInfo)} key={designInfo.key} className='design-masonry-link'>
                                    <div key={designInfo.key} className="design-masonry-parent">
                                        <img className="design-masonry-img"
                                            src={designInfo.designFilePath}
                                        />
                                        <div className="design-mansory-tag">
                                            {/* タグエリア */}
                                            <ul>
                                                {
                                                designInfo.tagList.map((tag, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                        <li> {tag} </li>
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                        <div className='design-mansory-datetime'>
                                            {/* 作成年月日 */}
                                            <span>{designInfo.createdYmd}</span>
                                        </div>
                                    </div>
                                </a>
                            ))}
                        </Masonry>
                    </ResponsiveMasonry>
                </div>
            </div>
        </div>
    );
}

export default DesignPc;