/* 読込: モジュール */
import React, { useEffect } from 'react';
/* 読込: コンポーネント */
/* 読込: CSS */
import '../../css/Contact.css';

function ContactSp (props) {
    useEffect(() => {
    }, []);

    return (
        <div>
            <div id='contact-content' className='contact-content'>
            </div>
        </div>
    );
}

export default ContactSp;