/* 読込: モジュール */
import React, {useState, useEffect} from 'react';
import ReactMarkdown from 'react-markdown';
import RemarkGfm from 'remark-gfm';
/* 読込: コンポーネント */
/* 読込: CSS */
/* 読込: その他 */

function SystemDetailContentPc (props) {
    /* 記事の内容を変数に代入 */
    const [reportId, setReportId] = useState(0);
    const [title, setTitle] = useState("");
    const [thumbnailPath, setThumbnailPath] = useState("");
    const [thumbnailAlt, setThumbnailAlt] = useState("");
    const [tagList, setTagList] = useState([]);
    const [updatedDatetime, setUpdatedDatetime] = useState("");
    const [views, setViews] = useState("");
    const [markdownContent, setMarkdownContent] = useState("");

    /* Markdownの要素修正用 */
    const H1 = ({ node, ...props }) => {
        return (
            <h1 id={node.position?.start.line.toString()}>{props.children}</h1>
        );
    }

    useEffect(() => {
        // データを取得: 親コンポーネント
        setReportId(props.reportId);
        // データを取得: サーバー
        setTitle(props.title);
        setThumbnailPath(props.thumbnailPath);
        setThumbnailAlt("システム 記事 サムネイル");
        setTagList(props.tagList);
        setUpdatedDatetime(props.updatedYmd);
        setViews(props.view);
        setMarkdownContent(props.markdownContent);
    });

    return (
        <div className='report-content-area'>
            <div className='report-content-title-area'>
                <h1 className='report-content-title'>{title}</h1>
                <div className='report-content-sub'>
                    <div className='system-report-tag'>
                        <ul>
                            {
                                tagList.map((tag, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                        <li> {tag} </li>
                                        </React.Fragment>
                                    )
                            })}
                        </ul>
                    </div>
                    <div className='report-content-sub-views-datetime'>
                        <div className='report-content-sub-views'>
                            <p>{views}Views</p>
                        </div>
                        <div className='report-content-sub-datetime'>
                            <p>last updated:</p>
                            <time>{updatedDatetime}</time>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <img className='report-content-thumbnail' src={thumbnailPath} alt={thumbnailAlt}/>
            </div>
            {/* markdownから参照 */}
            <div className='report-content-composition-area'>
                <ReactMarkdown components={{h1: H1}} remarkPlugins={[RemarkGfm]}>{markdownContent}</ReactMarkdown>
            </div>
        </div>
    )
}

export default SystemDetailContentPc;